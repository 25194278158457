import * as React from 'react';

import cn from 'classnames';

import { useGetUserQuery } from 'store/api';

import Card from 'components/card';
import Preloader from 'components/preloader';

import Detailing from './components/detailing';
import Learning from './components/learning';

import styles from './information.module.scss';

const CompanyInformation: React.FC = () => {
  const { data: userInformation, isLoading } = useGetUserQuery();

  const isDetailingAccess =
    userInformation?.organisation.detailingAccess ?? false;

  const isLearningAccess =
    userInformation?.organisation.learningAccess ?? false;

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <Card
          className={cn({
            [styles.cardDark]: !isDetailingAccess,
          })}>
          {isLoading ? (
            <Preloader />
          ) : (
            <Detailing isAccess={isDetailingAccess} />
          )}
        </Card>

        <Card
          className={cn({
            [styles.cardDark]: !isLearningAccess,
          })}>
          {isLoading ? <Preloader /> : <Learning isAccess={isLearningAccess} />}
        </Card>
      </div>
    </section>
  );
};

export default CompanyInformation;
