import { createContext, FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import { OrganisationInfoType } from 'reducers/organisationSlice';
import { RootState } from 'store';
import { useGetCompanyQuery } from 'store/api';
import { GetCompanyResponse } from 'store/api/type';

import Preloader from 'components/preloader';

import { QueryUserProps } from './type';

const getCompanyContext = createContext<GetCompanyResponse | undefined>(
  undefined,
);

const { Provider } = getCompanyContext;

const GetCompanyProvider: FC<QueryUserProps> = (props) => {
  const {
    children,
    errorComponent = null,
    loadingComponent = null,
    noDataComponent = null,
  } = props;

  const { id } = useSelector<RootState, OrganisationInfoType>(
    ({ organisation }) => organisation,
  );

  const { data, isLoading, isError } = useGetCompanyQuery(
    {
      id,
    },
    {
      skip: !id,
    },
  );

  if (isError) return errorComponent;

  if (isLoading) return loadingComponent ? loadingComponent : <Preloader />;

  if (!data) return noDataComponent;

  return <Provider value={data}>{children}</Provider>;
};

export const useGetCompany = () => {
  const context = useContext(getCompanyContext);

  if (!context) {
    throw new Error(
      'This component must be used within a <GetCompanyProvider> component.',
    );
  }

  return context;
};

export default GetCompanyProvider;
