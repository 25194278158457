import { DateFormatter } from '@internationalized/date';

const language = 'ru';

export const dateFormatter = new DateFormatter(language, {
  dateStyle: 'short',
});

export const dateAndTimeFormatter = new DateFormatter(language, {
  dateStyle: 'short',
  timeStyle: 'medium',
});

export interface GetFormattedDateProps {
  date: string;
  formatter: DateFormatter;
}

export const getFormattedDate = (props: GetFormattedDateProps) => {
  const { formatter } = props;

  const date = new Date(props.date);

  return formatter.format(date);
};

export const getCurrentDate = (options: Intl.DateTimeFormatOptions) => {
  const date = new Date();

  return date.toLocaleString('ru-RU', options);
};
