import Image from 'next/image';
import * as React from 'react';

import { Box } from '@mui/material';
import { PopupButton } from '@typeform/embed-react';
import cn from 'classnames';
import { useGetCompany } from 'provides/getCompany';

import DetailingIcon from 'public/static/images/detailing-logo.svg';
import AccessIcon from 'public/static/images/information/access.svg';
import ClientsIcon from 'public/static/images/information/clients.svg';
import CompanyIcon from 'public/static/images/information/company.svg';
import PresentationsIcon from 'public/static/images/information/presentations.svg';
import TimeIcon from 'public/static/images/information/time.svg';
import UsersIcon from 'public/static/images/information/users.svg';

import { useGetUserQuery } from 'store/api';

import Card from 'components/card';
import { CardSize } from 'components/card/type';

import { lexics } from 'data/lexics';

import { getCurrentDate } from 'utils/date';
import { sklonenieWord } from 'utils/letter';
import { getCompanyAccessLink } from 'utils/links';

import { InformationProps } from '../type';

import styles from '../styles.module.scss';

const { noData } = lexics.information;

export const Detailing: React.FC<InformationProps> = (props) => {
  const { isAccess } = props;

  const { detailing } = useGetCompany();

  const { data: userInformation } = useGetUserQuery();

  const cardSize: CardSize = CardSize.ExtraSmall;

  const isMaximumUsers = detailing.users === detailing.limitUsers;

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image
          src={DetailingIcon}
          width={215}
          height={30}
          alt="detailing_logo"
          unoptimized
        />
      </div>

      {isAccess ? (
        <>
          <ul className={styles.listDetailing}>
            <li className={styles.itemDetailing}>
              <Card size={cardSize} className={styles.card}>
                <div className={styles.itemImage}>
                  <Image
                    src={UsersIcon}
                    width={30}
                    height={30}
                    alt="users-icon"
                    unoptimized
                  />
                </div>

                <p className={styles.itemTitle}>Пользователи</p>

                <p
                  className={cn(styles.itemText, styles.itemValue, {
                    wrong: isMaximumUsers,
                  })}>
                  {detailing.users} <span>/ {detailing.limitUsers}</span>
                </p>
              </Card>
            </li>

            <li className={styles.itemDetailing}>
              <Card size={cardSize} className={styles.card}>
                <div className={styles.itemImage}>
                  <Image
                    src={TimeIcon}
                    width={30}
                    height={30}
                    alt="time-icon"
                    unoptimized
                  />
                </div>

                <p className={styles.itemTitle}>Общее время на визитах</p>

                <p className={cn(styles.itemText, styles.itemValue)}>
                  {detailing.spentTimeAll}
                </p>
              </Card>
            </li>

            <li
              className={cn(
                styles.itemDetailing,
                styles.itemDetailing_Company,
              )}>
              <Card size={cardSize} className={styles.card}>
                <div className={styles.itemImage}>
                  <Image
                    src={CompanyIcon}
                    width={30}
                    height={30}
                    alt="company-icon"
                    unoptimized
                  />
                </div>

                <p className={styles.itemTitle}>Компании</p>

                <Box
                  className={styles.itemValue}
                  sx={{
                    display: 'flex',
                  }}>
                  <p className={styles.itemText}>
                    <span>Vet: </span>

                    {detailing.companiesVet}
                  </p>

                  <p className={styles.itemText}>
                    <span>Retail: </span>

                    {detailing.companiesRetail}
                  </p>
                </Box>
              </Card>
            </li>

            <li className={styles.itemDetailing}>
              <Card size={cardSize} className={styles.card}>
                <div className={styles.itemImage}>
                  <Image
                    src={ClientsIcon}
                    width={30}
                    height={30}
                    alt="clients-icon"
                    unoptimized
                  />
                </div>

                <p className={styles.itemTitle}>Клиенты</p>

                <p className={cn(styles.itemText, styles.itemValue)}>
                  <span>Vet: </span>

                  {detailing.clients}
                </p>
              </Card>
            </li>

            <li className={styles.itemDetailing}>
              <Card size={cardSize} className={styles.card}>
                <div className={styles.itemImage}>
                  <Image
                    src={PresentationsIcon}
                    width={30}
                    height={30}
                    alt="presentation-icon"
                    unoptimized
                  />
                </div>

                <p className={styles.itemTitle}>Количество презентаций</p>

                <p className={cn(styles.itemText, styles.itemValue)}>
                  {detailing.presentations}
                </p>
              </Card>
            </li>
          </ul>

          <hr className={styles.separator} />

          <h3 className={styles.title}>
            За
            <b>
              {' '}
              {getCurrentDate({
                month: 'long',
                year: 'numeric',
              })}{' '}
              :
            </b>
          </h3>

          <ul className={styles.list}>
            <li className={styles.item}>
              <div className={styles.itemImage}>
                <Image
                  src={TimeIcon}
                  width={30}
                  height={30}
                  alt="information-users-icon"
                  unoptimized
                />
              </div>

              <p>
                Общее время на визитах составило:
                <b className={styles.itemValue}>
                  {' '}
                  {detailing.spentTimeInLastMonth || noData}
                </b>
              </p>
            </li>

            <li className={styles.item}>
              <div className={styles.itemImage}>
                <Image
                  src={TimeIcon}
                  width={30}
                  height={30}
                  alt="information-users-icon"
                  unoptimized
                />
              </div>

              <p>
                {sklonenieWord(detailing.visitedOrganisationsVetCounter, [
                  'Была посещена',
                  'Было посещено',
                  'Было посещено',
                ])}
                :<b> {detailing.visitedOrganisationsVetCounter} </b>
                {sklonenieWord(detailing.visitedOrganisationsVetCounter, [
                  'организация',
                  'организации',
                  'организаций',
                ])}{' '}
                Vet
              </p>
            </li>

            <li className={styles.item}>
              <div className={styles.itemImage}>
                <Image
                  src={TimeIcon}
                  width={30}
                  height={30}
                  alt="information-users-icon"
                  unoptimized
                />
              </div>

              <p>
                {sklonenieWord(detailing.visitedOrganisationsRetailCounter, [
                  'Была посещена',
                  'Было посещено',
                  'Было посещено',
                ])}
                :<b> {detailing.visitedOrganisationsRetailCounter} </b>
                {sklonenieWord(detailing.visitedOrganisationsRetailCounter, [
                  'организация',
                  'организации',
                  'организаций',
                ])}{' '}
                Retail
              </p>
            </li>

            <li className={styles.item}>
              <div className={styles.itemImage}>
                <Image
                  src={TimeIcon}
                  width={30}
                  height={30}
                  alt="information-users-icon"
                  unoptimized
                />
              </div>

              <p>
                {sklonenieWord(detailing.visitsCounter, [
                  'Был совершен',
                  'Было совершено',
                  'Было совершено',
                ])}
                :<b> {detailing.visitsCounter} </b>
                {sklonenieWord(detailing.visitsCounter, [
                  'визит',
                  'визита',
                  'визитов',
                ])}
              </p>
            </li>

            <li className={styles.item}>
              <div className={styles.itemImage}>
                <Image
                  src={UsersIcon}
                  width={30}
                  height={30}
                  alt="information-users-icon"
                  unoptimized
                />
              </div>

              <p>
                Больше всего визитов совершил представитель:
                <b className={styles.itemValue}>
                  {' '}
                  {detailing.bestUser || noData}
                </b>
              </p>
            </li>
          </ul>
        </>
      ) : (
        <div className={styles.access}>
          <div className={styles.accessImage}>
            <Image src={AccessIcon} width={18} height={18} alt="access-logo" />
          </div>
          <PopupButton
            id={getCompanyAccessLink({
              userInformation,
              project: 'Detailing',
            })}
            className={styles.accessText}>
            Запросить доступ
          </PopupButton>
        </div>
      )}
    </div>
  );
};

export default Detailing;
