import { NextPage } from 'next';
import Head from 'next/head';

import GetCompanyProvider from 'provides/getCompany';

import CompanyInformation from 'components/company/information';

const CompanyInformationPage: NextPage = () => {
  return (
    <>
      <Head>
        <title>Информация об организации</title>
      </Head>

      <GetCompanyProvider>
        <CompanyInformation />
      </GetCompanyProvider>
    </>
  );
};

export default CompanyInformationPage;
