import Image from 'next/image';
import * as React from 'react';

import { PopupButton } from '@typeform/embed-react';
import cn from 'classnames';
import { useGetCompany } from 'provides/getCompany';

import AccessIcon from 'public/static/images/information/access.svg';
import TimeIcon from 'public/static/images/information/time.svg';
import UsersIcon from 'public/static/images/information/users.svg';
import LearningIcon from 'public/static/images/learning-logo.svg';

import { useGetUserQuery } from 'store/api';

import { lexics } from 'data/lexics';

import { sklonenieWord } from 'utils/letter';
import { getCompanyAccessLink } from 'utils/links';

import { InformationProps } from '../type';

import styles from '../styles.module.scss';

const { noData } = lexics.information;

export const Learning: React.FC<InformationProps> = (props) => {
  const { isAccess } = props;

  const { learning } = useGetCompany();

  const { data: userInformation } = useGetUserQuery();

  const items = [
    {
      value: learning.spentTimeAll,
      text: 'Общее количество времени обучения пользователей',
      icon: TimeIcon,
    },
    {
      value: learning.modules,
      text: 'Количество обучающих модулей',
      icon: UsersIcon,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image
          src={LearningIcon}
          width={215}
          height={30}
          alt="learning_logo"
          unoptimized
        />
      </div>

      {isAccess ? (
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={styles.itemImage}>
              <Image
                src={UsersIcon}
                width={30}
                height={30}
                alt="information-users-icon"
                unoptimized
              />
            </div>

            <p>
              <b
                className={cn({
                  wrong: learning.users === learning.limitUsers,
                })}>
                {learning.users}{' '}
              </b>
              {sklonenieWord(learning.users, [
                'пользователь',
                'пользователя',
                'пользователей',
              ])}{' '}
              из {learning.limitUsers}
            </p>
          </li>

          {items.map((item) => {
            const { value, icon, text } = item;

            return (
              <li key={text} className={styles.item}>
                <div className={styles.itemImage}>
                  <Image
                    src={icon}
                    width={30}
                    height={30}
                    alt="information-users-icon"
                    unoptimized
                  />
                </div>

                <p>
                  {text}
                  <b> {value || noData}</b>
                </p>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={styles.access}>
          <div className={styles.accessImage}>
            <Image src={AccessIcon} width={18} height={18} alt="access-logo" />
          </div>
          <PopupButton
            id={getCompanyAccessLink({
              userInformation,
              project: 'Learning',
            })}
            className={styles.accessText}>
            Запросить доступ
          </PopupButton>
        </div>
      )}
    </div>
  );
};

export default Learning;
